import React, { useState, memo, useEffect } from 'react';
import axios from "axios";
import './Dashboard-component.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSessionId,getUserType } from '../common/Service'
//images
import icon1 from '../images/createFunction.png'
import icon2 from '../images/functionList.png'
import icon3 from '../images/writeMoney.png'
import icon4 from '../images/giftlist.png'
import icon5 from '../images/searchMoney.png'
import icon6 from '../images/subUser.png'

const Dashboard = () => {

      const navigate = useNavigate()
      const { t } = useTranslation()
      const [userType,setUserType] = useState('')

       useEffect(() => {
            const sid = getSessionId()
            if (sid == '') {
            navigate('/login', { replace: true })
            }
            setUserType(getUserType())
        }, [])

      return (
            <form className='dashboard'>
                  {
                        (userType == 'admin' || userType == 'user') && 
                        <div className='dashboard-tile'>
                              <img src={icon1} onClick={()=> navigate('/createfunction')}></img>
                              <span className='tile-name' onClick={()=> navigate('/createfunction')}>{t('createFunction')}</span>
                        </div>
                  }
                  {
                        (userType == 'admin' || userType == 'user') && 
                        <div className='dashboard-tile'>
                              <img src={icon2} onClick={() => navigate('/functionlist')}></img>
                              <span className='tile-name' onClick={() => navigate('/functionlist')}>{t('functionList')}</span>
                        </div>
                  }
                  {
                        (userType == 'admin' || userType == 'user' || userType == 'subuser') && 
                        <div className='dashboard-tile'>
                              <img src={icon3} onClick={() => navigate('/addmoney')}></img>
                              <span className='tile-name' onClick={() => navigate('/addmoney')}>{t('createGift')}</span>
                        </div>
                  }
                  {
                        (userType == 'admin' || userType == 'user') && 
                        <div className='dashboard-tile'>
                              <img src={icon4} onClick={() => navigate('/moneylist')}></img>
                              <span className='tile-name' onClick={() => navigate('/moneylist')}>{t('giftList')}</span>
                        </div>
                  }
                  {
                        (userType == 'admin' || userType == 'user') && 
                        <div className='dashboard-tile'>
                              <img src={icon5} onClick={() => navigate('/searchmoney')}></img>
                              <span className='tile-name' onClick={() => navigate('/searchmoney')}>{t('search')}</span>
                        </div>
                  }
                  {
                        (userType == 'admin' || userType == 'user') && 
                        <div className='dashboard-tile'>
                              <img src={icon6} onClick={() => navigate('/addsubuser')}></img>
                              <span className='tile-name' onClick={() => navigate('/addsubuser')}>{t('addsubuser')}</span>
                        </div>
                  }
            </form>
      );
}

export default memo(Dashboard);