import { memo } from 'react';

const ProgressBar = () => {

    const style = { 
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: 'gray',
        zIndex: '9',
        opacity: '0.5',
        textAlign: 'center',
        paddingTop: '20%',
        boxSizing: 'border-box'
     }

    return(
        <div style={style}>
            <progress value={null} />
        </div>
    );

}

export default memo(ProgressBar);