import { createSlice, configureStore } from '@reduxjs/toolkit'

// progress bar store
const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState: {
    value: false
  },
  reducers: {
    progressShow: (state) => {
      state.value = true;
    },
    progressHide: (state) => {
      state.value = false;
    }
  }
})
export const { progressShow, progressHide } = progressBarSlice.actions
export const progressBarStore = configureStore({
  reducer: progressBarSlice.reducer
})

// alert message store
const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    isShow: false,
    message: '',
    status: 0
  },
  reducers: {
    showAlert: (state, data) => {
      state.isShow = true
      state.message = data.payload.message
      state.status = data.payload.status
    },
    hideAlert: (state) => {
      state.isShow = false
      state.message = ''
      state.status = 0
    }
  }
})
export const { showAlert, hideAlert } = alertSlice.actions
export const alertStore = configureStore({
  reducer: alertSlice.reducer
})

// tamil keyboard
const keyboardSlice = createSlice({
  name: 'keyboard',
  initialState: {
    value: false,
    position: {},
    fieldName: '',
    fieldValue: ''
  },
  reducers: {
    showKeyboard: (state, data) => {
      state.value = true;
      state.position = data.payload
    },
    hideKeyboard: (state) => {
      state.value = false;
    }
  }
})
export const { showKeyboard, hideKeyboard } = keyboardSlice.actions
export const keyboardStore = configureStore({
  reducer: keyboardSlice.reducer
})

// keyboard update store
const inputUpdateSlice = createSlice({
  name: 'inputUpdate',
  initialState: {
    fieldName: '',
    fieldValue: ''
  },
  reducers: {
    setFieldValue: (state, data) => {
      if(data.payload == 'd') {
        state.fieldValue = state.fieldValue.substring(0, state.fieldValue.length - 1);
      }else if(data.payload == 'x') {
        state.fieldValue = ''
      }else {
        state.fieldValue = state.fieldValue + data.payload
      }
    },
    setFieldName: (state, data) => {
      state.fieldName = data.payload
    }
  }
})
export const { setFieldValue, setFieldName } = inputUpdateSlice.actions
export const inputUpdateStore = configureStore({
  reducer: inputUpdateSlice.reducer
})


// session expired update
const sessionExpiredSlice = createSlice({
  name: 'sessionExpired',
  initialState: {
    value: false
  },
  reducers: {
    updateSessionStatus: (state) => {
      state.value = true;
    }
  }
})
export const { updateSessionStatus } = sessionExpiredSlice.actions
export const sessionExpiredStore = configureStore({
  reducer: sessionExpiredSlice.reducer
})


