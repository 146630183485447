import axios from 'axios'
import { progressBarStore, progressShow, progressHide } from './store';
import { sessionExpiredStore, updateSessionStatus } from '../common/store';
import { getSessionId, removeSessionId, removeUserId, removeUserName } from '../common/Service'
import { useNavigate } from 'react-router-dom';

axios.interceptors.request.use(
  config => {
    const token = getSessionId()
    if (token) {
      config.headers['Authorization'] = token
    }
    progressBarStore.dispatch(progressShow())
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    progressBarStore.dispatch(progressHide())
    if (response && response.data && response.data.rows && response.data.rows[0] && response.data.rows[0].checksession 
      && response.data.rows[0].checksession == 'sessionexpired') {
      removeSessionId()
      removeUserId()
      removeUserName()
      sessionExpiredStore.dispatch(updateSessionStatus())
      throw new Error('Session expired. Login again.');
    }else {
      return response
    }
  },
  function (error) {
    progressBarStore.dispatch(progressHide())
    return Promise.reject(error)
  }
)