import React from 'react';
import {QRCodeCanvas} from 'qrcode.react';

const PaymentQR = ({amt}) => {

    // const upiData = {
    //     pa: "aruljeeva.g-1@okicici",
    //     pn: "Jeeva",
    //     am: "100.00",
    //     cu: "INR",
    //     tn: "moinottu"
    //   };

      const upiData = `upi://pay?pa=aruljeeva.g-1@okicici&pn=moinottu&am=${amt}&cu=INR&tn=moinottu`;
     

    //   const upiString = new URLSearchParams(upiData).toString();


    return (
      <div>
       <QRCodeCanvas value={upiData} />
      </div>
    );
  };

  export default PaymentQR;