import React, { useState, useEffect, memo } from 'react';
import axios from "axios";
import './SearchMoney-Component.css';
import ReactTable from '../common/React-Table';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alertStore, showAlert } from '../common/store';
import { getUserId, setGiftMoney } from '../common/Service';
import { apiUrl } from '../common/Config'

//images
import img1 from '../images/searchMoney.png'

function SearchMoneyComponent(props) {
  const navigate = useNavigate();
  const [functions, setFunctions] = useState([]);
  const [selectedFunctionId, setSelectedFunctionId] = useState('');
  const [searchCategory, setSearchCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchText, setSearchText] = useState('');
  const [moneyList, setMoneyList] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');
  const { t } = useTranslation();
  
  useEffect(() => {
    console.log('money list use effect');
      let requestData = {
        userId: getUserId()
      }
      axios.post(apiUrl + "function/getFunctionList", requestData)
      .then(response => {
        if (response.data.rowCount > 0) {
          response.data.rows.push({ function_id: 'select', function_name: t('select') })
          setFunctions(response.data.rows);
        }else {
          setFunctions([{ function_id: 'select', function_name: t('select') }]);
        }
        setSelectedFunctionId('select');
      }).catch((error) => {
        alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
      });
    //};

    let x = [{ name: t('select'), value: '' }, { name: t('name'), value: 'name' }, { name: t('place'), value: 'place' }, { name: t('phonenumber'), value: 'phonenumber' }]
    setSearchCategory(x)
    setSelectedCategory('')

  }, [])

  const btnSearchText = () => {

    if (pageValidation()) {
      let requestData = {
        functionId: selectedFunctionId,
        searchCategory: selectedCategory,
        searchText: searchText
      }
      axios.post(apiUrl + "function/searchMoney", requestData)
      .then(response => {
        if(response.data.rowCount > 0)
        setMoneyList(response.data.rows)
      else setMoneyList([])
      })
      .catch(error => {
        alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
      });
    }
  }

  const pageValidation = () => {
    if (selectedFunctionId == 'select') {
      alertStore.dispatch(showAlert({ message: t('selectFunction'), status: 0 }))
      return false
    }
    if (searchText != '' && selectedCategory == '') {
      alertStore.dispatch(showAlert({ message: t('selectSearchCategory'), status: 0 }))
      return false
    }
    return true
  }

  const clearPage = () => {
    setSelectedFunctionId('select')
    setSelectedCategory('')
    setSearchText('')
    setMoneyList([])
  }

  const columns = [{
    id: "select",
    //accessor: (row) => row,
    Cell: ({ row }) => <input type="radio" value={row.index} onChange={(e) => setSelectedRow(e.target.value)} />
  }, {
    Header: t('name'),
    accessor: 'r_name'
  }, {
    Header: t('place'),
    accessor: 'r_place'
  }, {
    Header: t('amount'),
    accessor: 'r_amount'
  }, {
    Header: t('phonenumber'),
    accessor: 'r_phonenumber'
  }, {
    Header: t('comment'),
    accessor: 'r_comment'
  }]

  const goToPage = () => {
    console.log('go to dashboard');
    navigate('/dashboard', { replace: true });
  }

  const editGiftComment = () => {
    if (selectedRow == '') {
      alertStore.dispatch(showAlert({ message: t('selectOneRecord'), status: 0 }))
    } else {
      console.log(selectedRow)
      let x = moneyList[selectedRow]
      let fn = functions.filter((item) => { return item.function_id == selectedFunctionId });
      x.functionName = fn[0].function_name
      x = JSON.stringify(x)
      setGiftMoney(x)
      navigate('/editmoney', { replace: true });
    }
  }

  return (
    <div className='page-main'>

      <div className='page-title'>
        <img src={img1}></img>
        <h3>{t('search')}</h3>
      </div>

      <table>
        <tr>
          <td>{t('functionName')} <span className='mandatory-field'>*</span></td>
          <td>:</td>
          <td>
            <select className='select-width-1'
              value={selectedFunctionId}
              onChange={(e) => setSelectedFunctionId(e.target.value)}
            >
              {functions.map((option) => (
                <option key={option.function_id} value={option.function_id}>
                  {option.function_name}
                </option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          <td>{t('searchCategory')}</td>
          <td>:</td>
          <td>
            <select className='select-width-1'
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {searchCategory.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          <td>{t('search')}</td>
          <td>:</td>
          <td>
            <input name="search" type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          </td>
        </tr>
      </table>
      <div className='form-buttons'>
        <input type="button" className="back-button" value={t('goback')} onClick={goToPage} />
        <input type="button" className="back-button" value={t('clear')} onClick={clearPage} />
        <input type="button" value={t('search')} onClick={btnSearchText} />
      </div>
      <div className='form-buttons'>
        <input type="button" value={t('editComment')} className={(moneyList.length == 0 ? 'disable-field' : '')} onClick={editGiftComment} />
      </div>
      <ReactTable
        data={moneyList}
        columns={columns}
        defaultPageSize={10}
        pageSizeOptions={[5, 10, 15]}
      />
    </div>
  );
}

export default memo(SearchMoneyComponent);