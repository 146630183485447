import React, { useState, useEffect, memo } from 'react';
import axios from "axios";
import './FunctionList-component.css';
import ReactTable from '../common/React-Table';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alertStore, showAlert } from '../common/store';
import { getUserId } from '../common/Service';
import { apiUrl } from '../common/Config'

//images
import imgFunctionList from '../images/functionList.png'

function FunctionList(props) {
  const navigate = useNavigate();
  const [functionList, setFunctionList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const { t } = useTranslation();
  const data = { name: 'John Doe', age: 25 };

  useEffect(() => {

    let requestData = {
      userId: getUserId()
    }
    axios.post(apiUrl + "function/getFunctionList", requestData)
      .then(response => {
        setFunctionList(response.data.rows)
      })
      .catch(error => {
        alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
      });

  }, [])

  const viewAddPage = (() => {
    if (selectedRow != -1) {
      let x = {
        functionList: functionList,
        selectedIndex: selectedRow
      }
      navigate('/addmoney', { state: x });
    } else {
      alertStore.dispatch(showAlert({ message: t('selectOneRecord'), status: 0 }))
    }
  })

  const viewListPage = (() => {
    if (selectedRow != -1) {
      let x = {
        functionList: functionList,
        selectedIndex: selectedRow
      }
      navigate('/moneylist', { state: x });
    } else {
      alertStore.dispatch(showAlert({ message: t('selectOneRecord'), status: 0 }))
    }
  })

  const selectRow = (data => {
    setSelectedRow(data.rowIndex);
  })

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"];

    let day = date.getDate();
    if (day < 10) day = '0' + day;

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day}-${monthName}-${year}`;
  }


  const columns = [
    {
      Header: t('functionName'),
      accessor: 'function_name'
    }, {
      Header: t('functionPlace'),
      accessor: 'function_place'
    }, {
      Header: t('description'),
      accessor: 'function_description'
    }, {
      Header: t('date'),
      accessor: function (row, rowIndex) {
        return dateFormat(row.function_date);
      }
    }]

  const goToPage = () => {
    console.log('go to dashboard');
    navigate('/dashboard', { replace: true });
  }

  return (
    <div className='page-main'>

      <div className='page-title'>
        <img src={imgFunctionList}></img>
        <h3>{t('functionList')}</h3>
      </div>

      <ReactTable
        data={functionList}
        columns={columns}
        defaultPageSize={10}
        pageSizeOptions={[5, 10, 15]}
      />

      <div className='form-buttons'>
        <input type="button" className='back-button' value={t('goback')} onClick={goToPage} />
      </div>
      
    </div>
  );
}

export default memo(FunctionList);