import { memo, useState } from 'react';
import './Show-message-component.css'
import { useTranslation } from 'react-i18next';
import { hideAlert, alertStore } from './store';
//import { useNavigate } from 'react-router-dom';

//image
import imgBell from '../images/bell.png'
import imgClose from '../images/close.png'

function ShowMessage() {

    console.log('show message component');
    const { t } = useTranslation();
    const [alert, setAlert] = useState({ show: false, message: '', status: 0 })
    //const navigate = useNavigate()



    const closeMessage = () => {
        alertStore.dispatch(hideAlert())
        // if(alert.message == 'Session expired. Login again.') {
        //     navigate('/login', { replace: true });
        // }
    }

    alertStore.subscribe(() => {
        let alert = alertStore.getState()
        if (alert.isShow)
            setAlert({ show: true, message: alert.message, status: alert.status });
        else
            setAlert({ show: false, message: '', status: 0 });
    })

    return (
        <>
            {
                alert.show == true &&
                    <>
                        <div className='show-message'>
                            <div className={'common-alert ' + (alert.status == 1 ? 'common-alert-success' : 'common-alert-failure')}>
                                <div className='sm-title'>
                                    <img className='img-top' src={imgBell}></img>
                                    <span className='common-alert-close' onClick={closeMessage}><img className='img-top' src={imgClose}></img></span>
                                </div>
                                {
                                    typeof alert.message == 'object' ?

                                        <div className='sm-message'>
                                            {
                                                alert.message.map(item => (
                                                    <p>{item}</p>
                                                ))
                                            }
                                        </div>
                                        :
                                        <div className='sm-message'>
                                            {alert.message}
                                        </div>
                                }
                            </div >
                        </div>
                        <div className='back-layer'></div>
                    </>
            }
        </>
    );
}

export default memo(ShowMessage);