import React, { useState, memo } from 'react';
import { Image, Text, View, Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import font from '../common/fonts/Lohit-Tamil.ttf'
import { useTranslation } from 'react-i18next';

Font.register({
    family: "mytamilfont",
    src: font
});

const Report = (props) => {
    console.log('report js');
    const reciept_data = props.data;
    const logo = './pdflogo.PNG';
    const { t } = useTranslation();

    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: "#3E3E3E" },

        titleContainer: { flexDirection: 'row', marginTop: 5 },

        logo: { width: 90 },

        reportTitle: { fontSize: 16, textAlign: 'center', fontFamily: 'mytamilfont' },
        functionTitle: { fontSize: 10, textAlign: 'center', fontFamily: 'mytamilfont' },

        addressTitle: { fontSize: 11, fontStyle: 'bold' },

        report: { fontWeight: 'bold', fontSize: 20 },

        reportNumber: { fontSize: 11, fontWeight: 'bold' },

        address: { fontWeight: 400, fontSize: 10 },

        theader: { marginTop: 20, fontSize: 10, paddingTop: 4, paddingLeft: 7, flex: 1, height: 20, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, fontFamily: 'mytamilfont' },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbody: { fontSize: 10, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, fontFamily: 'mytamilfont' },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1.5, borderColor: 'whitesmoke', borderBottomWidth: 1 },

        tbody2: { flex: 2, borderRightWidth: 1, }

    });

    const ReportTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                {/* <Image style={styles.logo} src="pdflogo.PNG" /> */}
                <Text style={styles.reportTitle}>{reciept_data.name}</Text>
            </View>
        </View>
    );

    const FunctionDetails = () => (
        <View style={styles.titleContainer}>
            <View>
                <Text style={styles.functionTitle}>{t('functionName')}: {reciept_data.functionName}</Text>
                <Text style={styles.functionTitle}>{t('description')}: {reciept_data.description}</Text>
                <Text style={styles.functionTitle}>{t('date')}: {reciept_data.date}</Text>
                <Text style={styles.functionTitle}>{t('place')}: {reciept_data.place}</Text>
            </View>
        </View>
    );

    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 0 }}>
            {
                reciept_data.headers.map((header) => (
                    <View style={styles.theader}>
                        <Text>{header}</Text>
                    </View>
                ))
            }
        </View>
    );

    const TableBody = () => (
        reciept_data.items.map((receipt) => (
            <div key={receipt.id}>
                {/* <span>name:{receipt.namee}</span> */}
                <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={styles.tbody}>
                        <Text >{receipt.namee}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.place} </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.phonenumber}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.commentt}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.amount}</Text>
                    </View>
                </View>
            </div>
        ))
    );

    const TableTotal = () => (
        <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={styles.total}>
                <Text></Text>
            </View>
            <View style={styles.total}>
                <Text></Text>
            </View>
            <View style={styles.total}>
                <Text></Text>
            </View>
            <View style={styles.tbody}>
                <Text>{t('total')}</Text>
            </View>
            <View style={styles.tbody}>
                <Text>
                    {reciept_data.items.reduce((sum, item) => (sum + Number(item.amount)), 0)}
                </Text>
            </View>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <ReportTitle />
                <FunctionDetails />
                <TableHead />
                <TableBody />
                <TableTotal />
            </Page>
        </Document>
    )
}
export default memo(Report);