import { React, useState } from 'react';
import './Home-component.css';
import { useTranslation } from 'react-i18next';

//images
import imgTitle from '../images/title.png'
import imgMail from '../images/mail.png'

const Home = () => {
  const { t } = useTranslation();
  return ( 
    <form className='form-home'>

      <div className='page-title'>
        <img src={imgTitle}></img>
        <h3>{t('homeTitle')}..!</h3>
      </div>

      <p>{ t('homeDescription1') }</p>

      <p>{ t('homeDescription2') }</p>

      <p>{ t('homeDescription3') }</p>

      <p>{ t('homeDescription4') }</p>

      <p><b>{t('contact')}:</b></p>

      <div className='div-position-relative'>
        <img src={imgMail} className='mail'></img> 
        <span className='mail-id'>customercare@moinottu.com</span>
      </div>

    </form>
  );
}

export default Home;