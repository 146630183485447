import React, { useState, memo, useMemo, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserId, getUserName, getSessionId } from '../common/Service'
import i18next from "i18next";
import axios from "axios";
import './Menu-component.css';
import { sessionExpiredStore } from '../common/store'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { apiUrl } from '../common/Config'

//images
import imgTitle from '../images/title.png'
import imgLanguage from '../images/language.png'
import imgDashboard from '../images/dashboard.png'
import imgMobileMenu from '../images/mobileMenu.png'
import imgSignin from '../images/signin.png'
import imgLogin from '../images/login.png'
import imgUser from '../images/user.png'
import imgLogout from '../images/logout.png'

const Menu = () => {

    const [userId, setUserId] = useState(getUserId());
    const { i18n, t } = useTranslation();
    const [isDesktop, setIsDesktop] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate()
    const [alertMessage, setAlertMessage] = useState({});

      setTimeout(() => {
        setUserId(getUserId())
      });
    
      useLayoutEffect(() => {
        function updateSize() {
          if (window.innerWidth > 560) {
            console.log('desktop')
            setIsDesktop(true)
          } else {
            console.log('mobile')
            setIsDesktop(false)
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, [])
    
      useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
          i18next.changeLanguage("en");
        }
        if (window.innerWidth > 560) {
          setIsDesktop(true)
        }
        setUserId(getUserId())
      }, [userId]);

    const handleLanguageChange = (e) => {
        i18next.changeLanguage(e.target.value);
        toggleMenu()
    };

    const toggleMenu = () => {
      setShowMenu(!showMenu)
    }

    const logout = () => {
        const sid = getSessionId()
        if (sid != '') {
            setAlertMessage({ show: true, status: 1, message: t('logoutMessage') });
          let data = {
            sessionId: getSessionId()
          }
          axios.post(apiUrl + "login/logoutUser", data)
          .then(response => {
            if (response.status == 200) {
              sessionStorage.clear();
              navigate('/logout', { replace: true });
            }
          })
          .catch(error => {
            setAlertMessage({ show: true, status: 1, message: t('someErrorOccured') });
          });
        } else {
          setAlertMessage({ show: true, status: 1, message: t('sessionExpired') });
        }
        toggleMenu()
      }

      sessionExpiredStore.subscribe(() => {
        let x = sessionExpiredStore.getState()
        if (x.value)
            navigate('/login',{replace: true})
    })

    const gotoPage = (page) => {
      navigate(page)
      toggleMenu()
    }

    return (
        <div>
          { isDesktop == true ?
            <div className='menu-desktop-main'>

              <div className='menu-desktop title-width' onClick={()=> navigate('/home')}>
                <img className='img-desktop' src={imgTitle}></img>
                <span className='text-desktop'><b>{t('title')}</b></span>
              </div>

              {
                userId == "" ?
                  <>
                    <div className='app-header-right login-width' onClick={()=> navigate('/login')}>
                      <img className='img-desktop' src={imgLogin}></img>
                      <span className='text-desktop'>{t('login')}</span>
                    </div>
                    <div className='app-header-right sign-in-width' onClick={()=> navigate('/registration')}>
                      <img className='img-desktop' src={imgSignin}></img>
                      <span className='text-desktop'>{t('signin')}</span>
                    </div>
                  </>
                  : 
                  <>
                    <div className='app-header-right logout-width' onClick={logout}>
                      <img className='img-desktop' src={imgLogout}></img>
                      <span className='text-desktop'>{t('logout')}</span>
                    </div>
                    <div className='menu-desktop dashboard-width' onClick={()=> navigate('/dashboard')}>
                      <img className='img-desktop' src={imgDashboard}></img>
                      <span className='text-desktop'>{t('dashboard')}</span>
                    </div>
                  </>
              }

              <div className='app-header-right language-width'>
                <img className='img-desktop' src={imgLanguage}></img>
                <select className="s-language text-desktop" value={localStorage.getItem("i18nextLng")} onChange={handleLanguageChange}>
                  <option value="en">English</option>
                  <option value="ta">தமிழ்</option>
                </select>
              </div>

              { userId != "" && 
                <div className='app-header-right username-width'>
                  <img className='img-desktop' src={imgUser}></img>
                  <span className='text-desktop'>{getUserName()}</span> 
                </div> 
              }

            </div>
            :
            <div>
              <div className='menu-desktop-main'>

                <div className='menu-desktop title-width'>
                  <img className='img-desktop' src={imgTitle} onClick={()=>navigate('/home')}></img>
                  <span className='text-desktop' onClick={()=>navigate('/home')}><b>{t('title')}</b></span>
                </div>

                <div className='app-header-right'>
                  <img className='img-desktop' src={imgMobileMenu} onClick={toggleMenu}></img>
                </div>

              </div>

              {showMenu == true &&
                <div className='menu-popup-mobile'>
                  {
                    userId == "" ?
                      <>
                      <div className='menu-mobile'>
                        <span className='text-mobile' onClick={()=>gotoPage('/login')}>{t('login')}</span>
                        <img className='icon-mobile' src={imgLogin} onClick={()=>gotoPage('/login')}></img>
                      </div>
                      <div className='menu-mobile'>
                        <span className='text-mobile' onClick={()=>gotoPage('/registration')}>{t('signin')}</span>
                        <img className='icon-mobile' src={imgSignin} onClick={()=>gotoPage('/registration')}></img>
                      </div>
                      </>
                      :
                      <>
                        <div className='menu-mobile'>
                          <span className='text-mobile cursor-default'>{getUserName()}</span> 
                          <img className='icon-mobile cursor-default' src={imgUser}></img>
                        </div>
                        <div className='menu-mobile'>
                          <span className='text-mobile' onClick={logout}>{t('logout')}</span>
                          <img className='icon-mobile' src={imgLogout} onClick={logout}></img>
                        </div>
                        <div className='menu-mobile'>
                          <span className='text-mobile' onClick={()=> gotoPage('/dashboard')}>{t('dashboard')}</span>
                          <img className='icon-mobile' src={imgDashboard} onClick={()=> gotoPage('/dashboard')}></img>
                        </div>
                      </>
                  }

                    <div className='menu-mobile'>
                      <select className="s-language text-mobile" value={localStorage.getItem("i18nextLng")} onChange={handleLanguageChange}>
                        <option value="en">English</option>
                        <option value="ta">தமிழ்</option>
                      </select>
                      <img className='icon-mobile' src={imgLanguage}></img>
                    </div>
                  

                </div>
                }

            </div>

          }

        </div>
    )
}

export default memo(Menu);