import React, { useState, useEffect, memo } from 'react';
import axios from "axios";
import './MoneyList-Component.css';
import ReactTable from '../common/React-Table';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import Report from "../common/Report";
import { useTranslation } from 'react-i18next';
import { alertStore, showAlert } from '../common/store';
import { getUserId } from '../common/Service';
import { apiUrl } from '../common/Config'

//images
import img1 from '../images/giftlist.png'

function MoneyListComponent(props) {
  const navigate = useNavigate();
  const [moneyList, setMoneyList] = useState([]);
  const [selectedFunctionId, setSelectedFunctionId] = useState('');
  const [functions, setFunctions] = useState([]);
  const { t } = useTranslation();
  const [pdfData, setPdfData] = useState({
    date: "-",
    place: '-',
    description: '-',
    functionName: "-"
  });

  const downloadData = {
    name: t('giftNote'),
    date: pdfData.date,
    place: pdfData.place,
    description: pdfData.description,
    functionName: pdfData.functionName,
    headers: [t('name'), t('place'), t('phonenumber'), t('comment'), t('amount')],
    items: moneyList
  }

  console.log('money list component');

  let location = useLocation();
  useEffect(() => {
    console.log('money list use effect');
    //if (location && location.state) {
    //  let s = location.state;
    //  setFunctions(s.functionList);
    //  setSelectedFunctionId(s.functionList[s.selectedIndex].functionid);
    //} else {
      
      let requestData = {
        userId: getUserId()
      }
      axios.post(apiUrl + "function/getFunctionList", requestData)
      .then(response => {
        if (response.data.rowCount > 0) {
          response.data.rows.push({ function_id: 'select', function_name: t('select') })
          setFunctions(response.data.rows);
          setSelectedFunctionId('select');
        }else{
          setFunctions([{ function_id: 'select', function_name: t('select')}]);
          setSelectedFunctionId('select');
        }
      })
      .catch((error) => {
        alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
      });
    //};
  }, [])

  useEffect(() => {
    if (functions.length > 0) {
      let selectedFunction = functions.filter((item) => { return item.function_id == selectedFunctionId });
      setPdfData({
        functionName: selectedFunction[0].function_name,
        date: selectedFunction[0].function_date ? selectedFunction[0].function_date.substring(0, 10) : '',
        place: selectedFunction[0].function_place,
        description: selectedFunction[0].function_description
      })
    }

    if (selectedFunctionId && selectedFunctionId != 'select') {
      let requestData = {
        functionId: selectedFunctionId
      }
      axios.post(apiUrl + "function/getMoneyList", requestData)
      .then(response => {
        if(response.data.rowCount > 0) {
          setMoneyList(response.data.rows)
        }
      })
      .catch(error => {
        alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
      });
    }else {
      setMoneyList([])
    }
  }, [selectedFunctionId]);

  const columns = [{
    Header: t('place'),
    accessor: 'place'
  }, {
    Header: t('name'),
    accessor: 'namee'
  }, {
    Header: t('amount'),
    accessor: 'amount'
  }, {
    Header: t('phonenumber'),
    accessor: 'phonenumber'
  }, {
    Header: t('comment'),
    accessor: 'commentt'
  }]

  const goToPage = () => {
    console.log('go to dashboard');
    navigate('/dashboard', { replace: true });
  }

  return (
    <div className='page-main'>

      <div className='page-title'>
        <img src={img1}></img>
        <h3>{t('giftList')}</h3>
      </div>

      <div>
        <label>{t('functionName')}: </label>
        <select className='select-width'
          value={selectedFunctionId}
          onChange={(e) => setSelectedFunctionId(e.target.value)}
        >
          {functions.map((option) => (
            <option key={option.function_id.toString()} value={option.function_id.toString()}>
              {option.function_name}
            </option>
          ))}
        </select>
      </div>
      <ReactTable
        data={moneyList}
        columns={columns}
        defaultPageSize={10}
        pageSizeOptions={[5, 10, 15]}
      />
      <div className='form-buttons'>
        <input type="button" className="back-button" value={t('goback')} onClick={goToPage} />
        <PDFDownloadLink className={(moneyList.length == 0 ? 'disable-field' : '')} document={<Report data={downloadData} />} fileName='GiftNote.pdf'>
          <input type="button" value={t('downloadPDF')} />
        </PDFDownloadLink>
      </div>

      {/* don't delete this code, it is using to view pdf report in the page */}
      {/* <PDFViewer width="1000" height="650" className="app" >
        <Report data={downloadData} />
      </PDFViewer> */}
    </div>
  );
}

export default memo(MoneyListComponent);