import React, { useState, memo, useEffect } from 'react';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import './Registration-component.css';
import { alertStore, showAlert } from '../common/store';
import {  hcode, getLanguage } from '../common/Service'
import { apiUrl } from '../common/Config'
import { useNavigate } from 'react-router-dom';
import KeyboardIcon from '../common/keyboard-icon'

//images
import imgSignin from '../images/signin.png'
import eyeIcon from '../images/eye.png'

const Registration = () => {

  console.log('Registration component');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [place, setPlace] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('password');
  const { t } = useTranslation();
  const navigate = useNavigate()

  const clearData = () => {
    setName('')
    setPlace('')
    setPhoneNumber('')
    setPassword('')
  }

    useEffect(()=>{
      if(phoneNumber.length == 10) {
        try {
            let data = {
              phoneNumber: phoneNumber
            }
            axios.post(apiUrl + "login/getUserInfoByPhoneNumber", data)
            .then(response => {
              if(response && response.data && response.data.rowCount == 1) {
                setName(response.data.rows[0].user_name)
                setPlace(response.data.rows[0].user_place)
              }
            }).catch(error => {
              alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
            });
        } catch (error) {
          console.log(error);
        }
      }else{
        setName('')
        setPlace('')
      }
    },[phoneNumber]);

  const submitData = () => {
    let error = pageValidation();
    if (error.length == 0) {

      let data = {
        phoneNumber: phoneNumber,
        name: name,
        place: place,
        password: hcode(password)
      }
      axios.post(apiUrl + "register/registerUser", data)
      .then(response => {
        if (response.status == 200 && response.data.rowCount == 1) {
          if(response.data.rows[0].register_user == 1) {
            alertStore.dispatch(showAlert({ message: t('userCreated'), status: 1 }))
            clearData()
            navigate('/login',{ replace: true })
          }else {
            alertStore.dispatch(showAlert({ message: t('userRegisteredAlready'), status: 0 }))
            clearData()
          }
        }
      })
      .catch(error => {
        alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
      });

    } else {
      alertStore.dispatch(showAlert({ message: error, status: 0 }))
    }
  }

  const pageValidation = () => {
    //field length given based on db input
    let error = [];

    if (phoneNumber == "" || phoneNumber == null) {
      error.push(t('valPhoneNumber'));
    } else if (isNaN(phoneNumber)) {
      error.push(t('valPhoneNumberOnly'));
    } else if (phoneNumber.length != 10) {
      error.push(t('valPhoneNumberLength'));
    }

    if (name == "" || name == null) {
      error.push(t('validateName'));
    } else if (name.length > 300) {
      error.push(t('validateNameLength'));
    }

    if (name == "" || name == null) {
      error.push(t('valPlace'));
    } else if (name.length > 300) {
      error.push(t('valPlaceLength'));
    }

    // if (email == "" || email == null) {
    //   error.push(t('validateEmail'));
    // } else if (email.indexOf('@') == -1 || email.indexOf('.') == -1) {
    //   error.push(t('validateEmail'));
    // } else if (email.length > 150) {
    //   error.push(t('validateEmailValid'));
    // }
    
    if (password == "" || password == null) {
      error.push(t('validateCreatePassword'));
    } else if (password.length > 20) {
      error.push(t('validateCreatePasswordLength'));
    } else if (password.length < 6) {
      error.push(t('validateCreatePasswordlengthShort'));
    }
    return error;
  }

  const toggle = () => {
    if(type == 'password') {
      setType('text')
    }else{
      setType('password')
    }
  }

  return (
    <form onSubmit={submitData}>

      <div className='page-title'>
        <img src={imgSignin}></img>
        <h3>{t('register')}</h3>
      </div>
      
      <div>
        <label>{t('phonenumber')} <span className='mandatory-field'>*</span> ({phoneNumber.length})</label>
        <input name="phoneNumber" type="text" maxLength="10" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      </div>

      <div className='div-position-relative'>
        <label className="fn-class">{t('name')} <span className='mandatory-field'>*</span></label>
        <input name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        <KeyboardIcon input={name} sendToParent={(e) => setName(e)}></KeyboardIcon>
      </div>

      <div className='div-position-relative'>
        <label className="fn-class">{t('place')} <span className='mandatory-field'>*</span></label>
        <input name="place" type="text" value={place} onChange={(e) => setPlace(e.target.value)} />
        <KeyboardIcon input={place} sendToParent={(e) => setPlace(e)}></KeyboardIcon>
      </div>

      <div className='div-position-relative'>
        <label className="fn-class">{t('createPassword')} <span className='mandatory-field'>*</span></label>
        <input name="password" type={type} value={password} onChange={(e) => setPassword(e.target.value)} />
        <img src={eyeIcon} className='eye-icon eye-icon-ll' onClick={toggle}></img>
      </div>

      <div className='form-buttons'>
        <input type="button" className='back-button' value={t('clear')} onClick={clearData} />
        <input type="button" value={t('submit')} onClick={submitData} />
      </div>

    </form>
  );
}

export default memo(Registration);