import React from "react";
import { useTable } from "react-table";
import Table from "react-bootstrap/Table";
import './React-Table.css';
import { useTranslation } from 'react-i18next';

/**
 * As in the previous versions, a react-table accepts colums where at the core we have a field Header, and accessor
 * As in the previous versions, a react-table has data that consist of an array of JSONs
 */
const ReactTable = ({ columns, data }) => {
  const { t } = useTranslation();
  // you can get the react table functions by using the hook useTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });
  return (
    <div className="react-table">
      <Table bordered {...getTableProps()} className="table" striped hover variant="dark">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                const { render, getHeaderProps } = column
                return (
                  <th {...getHeaderProps()}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
          {
            rows.length == 0 && <p className="no-record">{t('noRecordsFound')}</p>
          }
        </tbody>
      </Table>
    </div>
  );
};

export default ReactTable;