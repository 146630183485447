const generateRandomNumber12 = () => {
    return Math.floor(100000000000 + Math.random() * 900000000000).toString()
}

const generateRandomNumber6 = () => {
    return Math.floor(100000 + Math.random() * 900000).toString()
}

export { generateRandomNumber12, generateRandomNumber6 }

