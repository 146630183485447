import React, { useState, memo } from 'react';
import './Logout-component.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//images
import imgLogout from '../images/logout.png'

const Logout = () => {

  const { i18n, t } = useTranslation();
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/home', { replace: true });
  }

  const goLogin = () => {
    navigate('/login', { replace: true });
  }

  return (
    <form>
      
      <div className='page-title'>
        <img src={imgLogout}></img>
        <h3>{t('logout')}</h3>
      </div>

      <p>{t('logoutMessage1')}</p>

      <p>{t('logoutMessage2')}</p>
      
      <div className='form-buttons'>
        <input type="button" className='back-button' value={t('home')} onClick={goHome} />
        <input type="button" value={t('login')} onClick={goLogin} />
      </div>

    </form>
  );
}

export default memo(Logout);