import { useState, memo, useEffect } from 'react'
import axios from "axios"
import { useNavigate, useParams  } from 'react-router-dom'
import './Login-component.css'
import { useTranslation } from 'react-i18next'
import { alertStore, showAlert } from '../common/store'
import ReactTable from '../common/React-Table'
import { apiUrl } from '../common/Config'

//images
import imgLastGift from '../images/lastGift.png'

const SearchComponent = (props) => {
    
    //const { phonenumber } = (props && props.match && props.match.params) ? props.match.params : ''
    let { phonenumber } = useParams();
    const [phone, setPhone] = useState('')
    const [searchList, setSearchList] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        if (phonenumber) {
            setPhone(phonenumber)
        }
    }, [])

    const search = () => {
        let error = pageValidation();
        if (error.length == 0) {
            let data = {
                phoneNumber: phone
            }
            axios.post(apiUrl + "function/searchLastGift", data)
                .then(response => {
                    if (response.data.rowCount == 1) {
                        let row = response.data.rows[0];
                        let x = [{
                            fieldName: t('yourName'),
                            fieldValue: row.your_name
                        }, {
                            fieldName: t('yourPlace'),
                            fieldValue: row.your_place
                        }, {
                            fieldName: t('yourAmount'),
                            fieldValue: row.your_amount
                        }, {
                            fieldName: t('searchFunctionName'),
                            fieldValue: row.function_name
                        }, {
                            fieldName: t('searchFunctionPlace'),
                            fieldValue: row.function_place
                        }]
                        setSearchList(x)
    
                    } else if (response.status == 200 && response.data.rowCount == 0) {
                        alertStore.dispatch(showAlert({ message: response.data.message, status: 0 }))
                    } else {
                        alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
                    }
                })
                .catch(error => {
                    alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
                });
        } else {
            alertStore.dispatch(showAlert({ message: error, status: 0 }))
        }
        
    }

    const pageValidation = () => {
        let error = [];

        if (phone == "" || phone == null) {
            error.push(t('valMobileOrReferenceNumber'));
        } else if (isNaN(phone)) {
            error.push(t('valNumberOnly'));
        } else if (phone.length != 10) {
            if (phone.length != 12) {
                error.push(t('valMobileOrReferenceNumber'));
            }
        }
        return error;
    }

    const columns = [
        {
            Header: t('fieldName'),
            accessor: 'fieldName'
        }, {
            Header: t('fieldValue'),
            accessor: 'fieldValue'
        }]

    const clearData = () => {
        setPhone('')
    }

    return (
        <form>

            <div className='page-title'>
                <img src={imgLastGift}></img>
                <h3>{t('latestGift')}</h3>
            </div>
            
            <div>
                <label>{t('mobileOrReferenceNumber')} <span className='mandatory-field'>*</span> ({phone.length})</label>
                <input name="phoneNumber" type="text" maxLength="12" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>

            <div className='form-buttons'>
                <input type="button" className='back-button' value={t('clear')} onClick={clearData} />
                <input type="button" value={t('search')} onClick={search} />
            </div>

            <ReactTable
                data={searchList}
                columns={columns}
                defaultPageSize={10}
                pageSizeOptions={[5, 10, 15]}
            />

        </form>
    )
}

export default memo(SearchComponent)