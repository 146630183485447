import React, { useState, memo, useEffect } from 'react';
import axios from "axios";
import './EditMoney-component.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alertStore, showAlert } from '../common/store';
import { getGiftMoney,getSessionId, removeGiftMoney } from '../common/Service'
import { apiUrl } from '../common/Config'
import KeyboardIcon  from '../common/keyboard-icon'

//images
import imgComment from '../images/comment.png'

function EditMoney(props) {
  console.log('edit money component');
  const { t } = useTranslation();
  const [functionName, setFunctionName] = useState('');
  const [name, setName] = useState('');
  const [place, setPlace] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');
  const [giftId, setGiftId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const sid = getSessionId()
    if (sid == '') {
    navigate('/login', { replace: true })
    return
    }
}, [])

  useEffect(() => {
    let x = getGiftMoney()
    if(x!='') {
      x = JSON.parse(x)
      setFunctionName(x.functionName);
      setName(x.r_name);
      setPlace(x.r_place)
      setPhoneNumber(x.r_phonenumber)
      setAmount(x.r_amount)
      setComment(x.r_comment)
      setGiftId(x.r_moneydetailid)
    }
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    try {
      let error = pageValidation();
      if (error == '') {
        let data = {
          giftId: giftId,
          comment: comment
        }
        axios.post(apiUrl + "function/editmoneycomment", data)
        .then(response => {
          removeGiftMoney()
          alertStore.dispatch(showAlert({ message: t('commentSavedSuccess'), status: 1 }))
          navigate('/searchmoney', { replace: true });
        })
        .catch(error => {
          alertStore.dispatch(showAlert({ message: error, status: 0 }))
        });
      } else {
        alertStore.dispatch(showAlert({ message: error, status: 0 }))
      }
    } catch (error) {
      alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
    }

  }

  const goToPage = () => {
    navigate('/searchmoney', { replace: true });
  }

  const clearData = () => {
    setComment('')
  }

  const pageValidation = () => {
    //field length given based on db input
    let error = [];
    if (comment == "" || comment == null) {
      error.push(t('validateComment'));
    }
    return error;
  }

  return (
    <form onSubmit={(e) => submitData(e)}>

      <div className='page-title'>
        <img src={imgComment}></img>
        <h3>{t('editComment')}</h3>
      </div>

      <table>
        <tr><td>{t('functionName')}</td><td>:</td><td>{functionName}</td></tr>
        <tr><td>{t('name')}</td><td>:</td><td>{name}</td></tr>
        <tr><td>{t('place')}</td><td>:</td><td>{place}</td></tr>
        <tr><td>{t('phonenumber')}</td><td>:</td><td>{phoneNumber}</td></tr>
        <tr><td>{t('amount')}</td><td>:</td><td>{amount}</td></tr>
      </table>

      <div className='div-position-relative'>
        <label>{t('comment')}:</label>
        <input name="comment" type="text" maxLength="1000" value={comment} onChange={(e) => setComment(e.target.value)} />
        <KeyboardIcon input={comment} sendToParent={(e) => setComment(e)}></KeyboardIcon>
      </div>

      <div className='form-buttons'>
        <input type="button" className='back-button' value={t('search')} onClick={goToPage} />
        <input type="submit" value={t('saveComment')} />
      </div>

    </form>
  );
}

export default memo(EditMoney);