import { useState, memo, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './Login-component.css';
import { useTranslation } from 'react-i18next';
import { alertStore, showAlert } from '../common/store';
import { setSessionId, setUserId, setUserName, hcode, setUserType, setParentId } from '../common/Service';
import { apiUrl } from '../common/Config'

//images
import imgLogin from '../images/login.png'

const Login = () => {

  console.log('login component');

  //const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('useEffect: login component');
  }, [])

  const clearData = () => {
    setPhoneNumber('');
    setPassword('');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = pageValidation();

    if (error.length == 0) {
      let x = phoneNumber.length == 10 ? hcode(password) : password
      let data = {
        phoneNumber: phoneNumber,
        password: x
      }
      axios.post(apiUrl + "login/loginUser", data)
        .then(response => {
          if (response.status == 200 && response.data.rowCount == 1) {
            let row = response.data.rows[0];
            if (row.status_code == 'LOGIN_SUCCESS') {
              setUserName(row.user_name)
              setUserId(row.user_id.toString())
              setSessionId(row.session_id)
              setUserType(row.user_type)
              setParentId(row.parentid.toString())
              let m = [t('loginSuccess') + row.user_name + '. ' + t('loginSuccess1'), t('loginSuccess2')]
              alertStore.dispatch(showAlert({ message: m, status: 1 }))
              clearData();
              navigate('/dashboard', { replace: true });
            } else {
              alertStore.dispatch(showAlert({ message: t(row.status_code), status: 0 }))
            }

          } else if (response.status == 200 && response.data.rowCount == 0) {
            alertStore.dispatch(showAlert({ message: response.data.message, status: 0 }))
          } else {
            alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
          }
        })
        .catch(error => {
          alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
        });
    } else {
      alertStore.dispatch(showAlert({ message: error, status: 0 }))
    }
  }

  const pageValidation = () => {
    //field length given based on db input
    let error = [];

    // if (email == "" || email == null) {
    //   error.push(t('validateEmail'));
    // } else if (email.indexOf('@') == -1 || email.indexOf('.') == -1) {
    //   error.push(t('invalidEmail'));
    // } else if (email.length > 150) {
    //   error.push(t('validateEmailValid'));
    // }

    if (phoneNumber == "" || phoneNumber == null) {
      error.push(t('valPhoneNumber'));
    } else if (isNaN(phoneNumber)) {
      error.push(t('valPhoneNumberOnly'));
    } else if (phoneNumber.length != 10) {
      if(phoneNumber.length != 12) {
        error.push(t('valPhoneNumberLength'));
      }
    }

    if (password == "" || password == null) {
      error.push(t('validatePassword'));
    } else if (password.length > 20) {
      error.push(t('validatePasswordLength'));
    } else if (password.length < 6) {
      error.push(t('validatePasswordLengthShort'));
    }
    return error;
  }

  return (
    <form>

    <div className='page-title'>
      <img src={imgLogin}></img>
      <h3>{t('loginUser')}</h3>
    </div>

      
      <div>
        <label>{t('phonenumber')} <span className='mandatory-field'>*</span> ({phoneNumber.length})</label>
        <input name="phoneNumber" type="text" maxLength="12" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      </div>
      <div>
        <label className="fn-class">{t('password')} <span className='mandatory-field'>*</span></label>
        <input name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className='form-buttons'>
        <input type="button" className='back-button' value={t('clear')} onClick={clearData} />
        <input type="button" value={t('login2')} onClick={handleSubmit} />
      </div>
    </form>
  );
}

export default memo(Login);