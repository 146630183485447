import CryptoJS from 'crypto-js'

const secretKey = '67890'
const sessionId = 'sisisi'
const userId = 'uiuiui'
const userName = 'ununun'
const userType = 'ututut'
const parentId = 'pipipi'
const giftMoneyData = 'gmdgmdgmd'


const encryptData = (data) => {
    const cipherText = CryptoJS.AES.encrypt(data, secretKey).toString()
    return cipherText
}

const decryptData = (data) => {
    if (data != null) {
        const bytes = CryptoJS.AES.decrypt(data, secretKey)
        const plainText = bytes.toString(CryptoJS.enc.Utf8)
        return plainText
    } else {
        return ''
    }
}

const setSessionId = (data) => {
    sessionStorage.setItem(sessionId, encryptData(data))
}

const getSessionId = () => {
    return decryptData(sessionStorage.getItem(sessionId))
}

const removeSessionId = () => {
    sessionStorage.removeItem(sessionId)
}

const setUserId = (data) => {
    sessionStorage.setItem(userId, encryptData(data))
}

const getUserId = () => {
    return decryptData(sessionStorage.getItem(userId))
}

const removeUserId = () => {
    sessionStorage.removeItem(userId)
}

const setUserName = (data) => {
    sessionStorage.setItem(userName, encryptData(data))
}

const getUserName = () => {
    return decryptData(sessionStorage.getItem(userName))
}

const removeUserName = () => {
    sessionStorage.removeItem(userName)
}

const setUserType = (data) => {
    sessionStorage.setItem(userType, encryptData(data))
}

const getUserType = () => {
    return decryptData(sessionStorage.getItem(userType))
}

const removeUserType = () => {
    sessionStorage.removeItem(userType)
}

const setGiftMoney = (data) => {
    sessionStorage.setItem(giftMoneyData, encryptData(data))
}

const getGiftMoney = () => {
    return decryptData(sessionStorage.getItem(giftMoneyData))
}

const removeGiftMoney = () => {
    sessionStorage.removeItem(giftMoneyData)
}

const hcode = (str) => {
    var hash = 0,
      i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
  }

  const getLanguage = () => {
    return localStorage.getItem('i18nextLng')
  }

  const setParentId = (data) => {
      sessionStorage.setItem(parentId, encryptData(data))
  }
  
  const getParentId = () => {
      return decryptData(sessionStorage.getItem(parentId))
  }

export { setSessionId, getSessionId, removeSessionId, setUserId, getUserId, removeUserId, setUserName, getUserName, 
    removeUserName, setGiftMoney, getGiftMoney, removeGiftMoney, hcode, getLanguage, setUserType,getUserType,removeUserType,setParentId,getParentId }
