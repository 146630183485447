import React, { useState, memo, useEffect } from 'react';
import axios from "axios";
import './AddMoney-component.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alertStore, showAlert } from '../common/store';
import { getParentId, getUserId, getUserType } from '../common/Service'
import { generateRandomNumber12 } from '../common/commonService'
import { apiUrl } from '../common/Config'
import ReactTable from '../common/React-Table';
import KeyboardIcon from '../common/keyboard-icon'

//images
import img1 from '../images/writeMoney.png'

const AddMoney = () => {
  const { t } = useTranslation();
  const [place, setPlace] = useState('');
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedFunctionId, setSelectedFunctionId] = useState('');
  const [functions, setFunctions] = useState([]);
  const [amountFormat, setAmountFormat] = useState('');
  const [comment, setComment] = useState('');
  const [userId, setUserId] = useState('');
  const [giftList, setGiftList] = useState([])

  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    let user_id = getUserType() == 'user' ? getUserId() : getParentId()

    let requestData = {
      userId: user_id
    }
    axios.post(apiUrl + "function/getFunctionList", requestData)
    .then(response => {
      if(response && response.data && response.data.rowCount > 0) {
        response.data.rows.push({ function_id: 'select', function_name: t('select') })
        setFunctions(response.data.rows);
        setSelectedFunctionId('select');
      }else{
        setFunctions([{ function_id: 'select', function_name: t('select') }]);
        setSelectedFunctionId('select');
      }
    }).catch(error => {
      alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
    });
  }, []);

  useEffect(() => {
    if(phoneNumber.length == 10) {
      try {
          let data = {
            phoneNumber: phoneNumber
          }
          axios.post(apiUrl + "function/getUserInfoByPhoneNumber", data)
          .then(response => {
            if(response && response.data && response.data.rowCount == 1) {
              setName(response.data.rows[0].user_name)
              setPlace(response.data.rows[0].user_place)
            }
          }).catch(error => {
            alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
          });
      } catch (error) {
        console.log(error);
      }
    }else{
      setName('')
      setPlace('')
    }
  },[phoneNumber]);

  const submitData = (e) => {
    e.preventDefault();

    try {
      let error = pageValidation();
      if (error.length == 0) {
      } else {
        alertStore.dispatch(showAlert({ message: error, status: 0 }))
        return
      }
      let data = {
        functionId: selectedFunctionId,
        phoneNumber: phoneNumber,
        name: name,
        place: place,
        amount: amount,
        comment: comment,
        createdBy: getUserType() == 'user' ? getUserId() : getParentId(),
        updatedBy: getUserType() == 'user' ? getUserId() : getParentId()
      }
      setGiftList([])

      axios.post(apiUrl + "function/addmoney", data)
      .then(response => {
        if(response && response.data && response.data.rowCount == 1) {
          if(response.data.rows[0].r_moneydetailid > 0) {
            setGiftList(response.data.rows)
            alertStore.dispatch(showAlert({ message: t('giftAmountSuccess'), status: 1 }))
            clearData();
          }else{
            alertStore.dispatch(showAlert({ message: t('giftAddedAlready'), status: 0 }))
          }
        }
      }).catch(error => {
        alertStore.dispatch(showAlert({ message: error.message, status: 0 }))
      });
    } catch (error) {
      console.log(error);
    }

  }

  const goToPage = () => {
    navigate('/dashboard', { replace: true });
  }

  const clearData = () => {
    setName('')
    setPlace('')
    setPhoneNumber('')
    setAmount('')
    setComment('')
    setUserId('')
  }

  const pageValidation = () => {
    //field length given based on db input
    let error = [];
    if (selectedFunctionId == "select") {
      error.push(t('valFunctionName'));
    }

    if (phoneNumber == "" || phoneNumber == null) {
      error.push(t('valPhoneNumber'));
    } else if (isNaN(phoneNumber)) {
      error.push(t('valPhoneNumberOnly'));
    } else if (phoneNumber.length != 10) {
      if(phoneNumber.length != 12) {
        error.push(t('valPhoneNumberLengthPlus'));
      }
    }

    if (name == "" || name == null) {
      error.push(t('validateName'));
    } else if (name.length > 300) {
      error.push(t('validateNameLength'));
    }
    if (place == "" || place == null) {
      error.push(t('valPlace'));
    } else if (place.length > 300) {
      error.push(t('valPlaceLength'));
    }
    if (amount == "" || amount == null) {
      error.push(t('valAmount'));
    } else if (amount.length > 10) {
      error.push(t('valAmountLength'));
    }else if(isNaN(amount)) {
      error.push(t('valAmountInvalid'));
    }
    return error;
  }

  const onDummyClick = () => {
    setPhoneNumber(generateRandomNumber12())
  }

  useEffect(() => {
    switch (amount.length) {
      case 0:
        setAmountFormat('0');
        break;
      case 1:
      case 2:
      case 3:
        setAmountFormat(amount);
        break;
      case 4:
        setAmountFormat(amount[0] + ',' + amount[1] + amount[2] + amount[3]);
        break;
      case 5:
        setAmountFormat(amount[0] + amount[1] + ',' + amount[2] + amount[3] + amount[4]);
        break;
      case 6:
        setAmountFormat(amount[0] + ',' + amount[1] + amount[2] + ',' + amount[3] + amount[4] + amount[5]);
        break;
      case 7:
        setAmountFormat(amount[0] + amount[1] + ',' + amount[2] + amount[3] + ',' + amount[4] + amount[5] + amount[6]);
        break;
      case 8:
        setAmountFormat(amount[0] + ',' + amount[1] + amount[2] + ',' + amount[3] + amount[4] + ',' + amount[5] + amount[6] + amount[7]);
        break;
      case 9:
        setAmountFormat(amount[0] + amount[1] + ',' + amount[2] + amount[3] + ',' + amount[4] + amount[5] + ',' + amount[6] + amount[7] + amount[8]);
        break;
      case 10:
        setAmountFormat(amount[0] + amount[1] + amount[2] + ',' + amount[3] + amount[4] + ',' + amount[5] + amount[6] + ',' + amount[7] + amount[8] + amount[9]);
        break;
    }
  }, [amount])

  const columns = [{
    Header: t('name'),
    accessor: 'r_name'
  }, {
    Header: t('place'),
    accessor: 'r_place'
  }, {
    Header: t('amount'),
    accessor: 'r_amount'
  }]

  return (
    <form onSubmit={(e) => submitData(e)}>
      
      <div className='page-title'>
        <img src={img1}></img>
        <h3>{t('createGift')}</h3>
      </div>

      <div>
        <label>{t('functionName')} <span className='mandatory-field'>*</span></label>
        <select
          value={selectedFunctionId}
          onChange={(e) => setSelectedFunctionId(e.target.value)}
        >
          {functions.map((option) => (
            <option key={option.function_id} value={option.function_id}>
              {option.function_name}
            </option>
          ))}
        </select>
      </div>

      <div className='div-phone-number'>
        <label>{t('phonenumber')} <span className='mandatory-field'>*</span> ({phoneNumber.length})</label>
        <input name="phoneNumber" type="text" maxLength="12" className='phone-number' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        <span className='dummy' onClick={onDummyClick}>{t('tempPhoneNumber')}</span>
      </div>

      <div className='div-position-relative'>
        <label>{t('name')} <span className='mandatory-field'>*</span></label>
        <input name="name" type="text" maxLength="600" value={name} onChange={(e) => setName(e.target.value)} />
        <KeyboardIcon input={name} sendToParent={(e) => setName(e)}></KeyboardIcon>
      </div>

      <div className='div-position-relative'>
        <label>{t('place')} <span className='mandatory-field'>*</span></label>
        <input name="place" type="text" maxLength="500" value={place} onChange={(e) => setPlace(e.target.value)} />
        <KeyboardIcon input={place} sendToParent={(e) => setPlace(e)}></KeyboardIcon>
      </div>

      <div>
        <label>{t('amount')} <span className='mandatory-field'>*</span> ({amountFormat})</label>
        <input name="amount" type="text" maxLength="10" value={amount} onChange={(e) => setAmount(e.target.value)} />
      </div>

      <div className='div-position-relative'>
        <label>{t('comment')}</label>
        <input name="comment" type="text" maxLength="1000" value={comment} onChange={(e) => setComment(e.target.value)} />
        <KeyboardIcon input={comment} sendToParent={(e) => setComment(e)}></KeyboardIcon>
      </div>
      
      <div className='form-buttons'>
        <input type="button" className='back-button' value={t('goback')} onClick={goToPage} />
        <input type="submit" value={t('saveGift')} />
      </div>
      { 
        giftList.length > 0 && 
        <div>
          <h3>{t('lastGiftAdded')}:</h3>
          <ReactTable
            data={giftList}
            columns={columns}
            defaultPageSize={10}
            pageSizeOptions={[5, 10, 15]}
          />
        </div>
      }
      
    </form>
  );
}

export default memo(AddMoney);