import React, { useState, memo, useEffect } from 'react';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import './AddSubUser-component.css'
import { alertStore, showAlert } from '../common/store';
import { hcode, getLanguage, getUserId } from '../common/Service'
import { generateRandomNumber6, generateRandomNumber12 } from '../common/commonService'
import { apiUrl } from '../common/Config'
import { useNavigate } from 'react-router-dom';
import ReactTable from '../common/React-Table';

//images
import eyeIcon from '../images/eye.png'
import imageThamil from '../images/thamil.png'
import PaymentQR from '../common/qrcode'
import KeyboardIcon from '../common/keyboard-icon'
import img1 from '../images/subUser.png'

const AddSubUserComponent = () => {
        const [name, setName] = useState('');
        const [type, setType] = useState('password');
        const { t } = useTranslation();
        const navigate = useNavigate()
        const [userList, setUserList] = useState([]);
        const [transId, setTransId] = useState('');
        const [notPaidCount, setNotPaidCount] = useState(0);
        const [amount] = useState(100);

        const clearData = () => {
                setName('')
        }

        useEffect(() => {
                let data = {
                        userId: getUserId()
                }
                axios.post(apiUrl + "register/getSubUsers", data)
                .then(response => {
                        let data = response.data
                        if (data.rowCount > 0) {
                                let x = []
                                let y = 0
                                data.rows.forEach(item => {
                                        if(item.paystatus == 0) {
                                                y = y+1;
                                        }
                                        x.push({ loginid: item.login_id, password: item.sub_user_password, name: item.sub_user_name, isActive: item.is_active, payStatus: item.paystatus, type: 'password', subUserId: item.sub_user_id })
                                });
                                setUserList(x)
                                setNotPaidCount(y)
                        }
                })
                .catch(error => {
                        alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
                });
        }, []);

        useEffect(() => {
                let x = 0
                userList.forEach(y => {
                        if(y.payStatus == 0) {
                                x++
                        }
                })
                setNotPaidCount(x)
        }, [userList]);

        const addUser = () => {
                let error = pageValidation();
                if (error.length == 0) {
                        let x = generateRandomNumber12()
                        let y = generateRandomNumber6()

                        let data = {
                                loginId: x,
                                name: name,
                                password: y,
                                userId: getUserId()
                        }
                        axios.post(apiUrl + "register/registerSubUser", data)
                                .then(response => {
                                        if (response.data.rowCount > 0) {
                                                if (response.data.rows[0].register_sub_user > 0) {
                                                        setUserList([...userList, { loginid: x, password: y, name: name, isActive: 0, payStatus: 0, type: 'password', subUserId: response.data.rows[0].register_sub_user }])
                                                        setNotPaidCount(userList.length+1)
                                                        clearData()
                                                }
                                        }
                                })
                                .catch(error => {
                                        alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
                                });
                } else {
                        alertStore.dispatch(showAlert({ message: error, status: 0 }))
                }
        }

        const makePayment = () => {

        }

        const pageValidation = () => {
                //field length given based on db input
                let error = [];

                if (name == "" || name == null) {
                        error.push(t('validateName'));
                } else if (name.length > 300) {
                        error.push(t('validateNameLength'));
                }
                return error;
        }

        const toggle = (index) => {
                let arr = [...userList]
                arr[index].type = arr[index].type == 'password' ? 'text' : 'password'
                setUserList(arr)
        }

        const convert = (r) => {
                return JSON.stringify(r)
        }

        const onSubmit = () => {
                let error = pageValidation2();
                if (error.length == 0) {
                        let x = []
                        userList.forEach((item)=>{
                                if(item.payStatus == 0) {
                                        x.push(item.subUserId)
                                }
                        })
                        let data = {
                                subUserIds: x,
                                transactionId: transId,
                        }
                        axios.post(apiUrl + "register/updatePaymentStatus", data)
                                .then(response => {
                                        if (response.data.rowCount > 0) {
                                                if (response.data.rows[0].update_payment_status > 0) {
                                                        let arr = userList.map((x) => ({
                                                                ...x,
                                                                isActive: 1,
                                                                payStatus: 1
                                                        }))
                                                        setUserList(arr)
                                                }
                                        }
                                })
                                .catch(error => {
                                        alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
                                });
                } else {
                        alertStore.dispatch(showAlert({ message: error, status: 0 }))
                }
        }

        const pageValidation2 = () => {
                //field length given based on db input
                let error = [];

                if (transId == "" || transId == null) {
                        error.push(t('validateTransactionId'));
                } else if (transId.length != 12) {
                        error.push(t('validateTransactionIdLength'));
                }else if(isNaN(transId)) {
                        error.push(t('validateTransactionIdNumber'));
                }
                return error;
        }

        const columns = [{
                Header: t('loginId'),
                accessor: 'loginid'
        }, {
                Header: t('password'),
                accessor: 'password',
                Cell: ({ cell }) => <span className='div-position-relative'><input name="pwd" className='pass-input' type={cell.row.original.type} value={cell.row.original.password} /><img src={eyeIcon} onClick={(e) => toggle(cell.row.index)} className='eye-icon eye-icon-l'></img></span>
        }, {
                Header: t('name'),
                accessor: 'name'
        }, {
                Header: t('isActive'),
                accessor: 'isActive',
                Cell: ({ cell }) => <span>{cell.row.original.isActive == 0 ? <span>No</span> : <span>Yes</span>}</span>
        }, {
                Header: t('paystatus'),
                accessor: 'payStatus',
                Cell: ({ cell }) => <span>{cell.row.original.payStatus == 0 && <span className='color-red'>Not Paid</span>} {cell.row.original.payStatus == 1 && <span className='color-yellow'>Pay verification pending</span>} {cell.row.original.payStatus == 2 && <span className='color-green'>Paid</span>}</span>
        }]

        return (
                <form className='form-sub-user'>
                        <div className='page-title'>
                                <img src={img1}></img>
                                <h3>{t('addsubuser')}</h3>
                        </div>

                        <p>{t('subUserDescription')}</p>
                        
                        <div className='div-position-relative'>
                                <label className="fn-class">{t('subUserName')} <span className='mandatory-field'>*</span></label>
                                <input name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                <KeyboardIcon input={name} sendToParent={(e) => setName(e)}></KeyboardIcon>
                        </div>

                        <div className='form-buttons'>
                                <input type="button" className='back-button' value={t('clear')} onClick={clearData} />
                                <input type="button" value={t('add')} onClick={addUser} />
                        </div>

                        <ReactTable
                                data={userList}
                                columns={columns}
                                defaultPageSize={10}
                                pageSizeOptions={[5, 10, 15]}
                        />
                        {
                                (userList.length > 0 && notPaidCount > 0) &&
                                <div>
                                        <span>{t('totalAmount')}{ notPaidCount * amount }</span>
                                        <span>UPI QR code</span>
                                        <PaymentQR amt={ notPaidCount * amount }></PaymentQR>
                                        <div>
                                                <label className="fn-class">{t('transHeader')}: <span className='mandatory-field'>*</span></label>
                                                <input name="trans" type="text" maxLength={12} value={transId} onChange={(e) => setTransId(e.target.value)} />
                                                <span>{t('transDescription')}</span>
                                        </div>
                                        <div className='form-buttons'>
                                                <input type="button" value={t('transSave')} onClick={onSubmit} />
                                        </div>
                                </div>


                        }

                </form>
        );
}

export default memo(AddSubUserComponent)