import React, { useState, memo, useEffect, useRef } from 'react';
import imageThamil from '../images/thamil.png'
import { letters, getLetters } from './letters-tamil'
import {  getLanguage } from '../common/Service'

const KeyboardIcon = ({input, sendToParent}) => {

    console.log('keyboard', input)

    const [showFirst, setShowFirst] = useState(true);
    const [showSecond, setShowSecond] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [letters2, setLetters2] = useState([]);

    const showKeyboardFn = () => {
        setShowKeyboard(!showKeyboard)
    }

    const keyboardIcon = {
        position: 'absolute',
        top: '23px',
        right: '8px',
        cursor: 'pointer'
    }

    const divStyle = {
        float: 'left'
    }

    const style = {
        position: 'absolute',
        backgroundColor: 'rgb(125 125 125)',
        zIndex: '9',
        marginTop:'50px'
    }

    const tdStyle = {
        backgroundColor: 'rgb(75 75 75)',
        cursor: 'pointer',
        textAlign: 'center',
        color: 'white',
        width: '35px',
        height: '25px'
    }

        const addText = (letter) => {
            // if (isTextBox) {
            //     setName(letter)
            // } else {
            //     let x;
            //     if (letter == '-')
            //         x = name + ' '
            //     else
            //         x = name + letter
            //     setName(x)
            // }
            if (letter == 'B') {
                setShowSecond(false)
                setShowFirst(true)
            } else {

                if(letter == 'd') {
                    let x = input.substring(0, input.length - 1)
                    sendToParent(x)
                  }else if(letter == 'x') {
                  }else {
                    sendToParent(input+letter)
                  }

                setShowSecond(false)
                setShowFirst(true)
            } 
        }
    
        const showText = (index) => {
            if (index == 21) {
                addText(' ')
            } else if (index == 22) {
                addText('d')
            } else if (index == 23) {
                setShowKeyboard(!showKeyboard)
            } else {
                setLetters2(getLetters(index))
                setShowSecond(true)
                setShowFirst(false)
            }
        }

    return (
        <>
            {getLanguage() == 'ta' && <img src={imageThamil} style={keyboardIcon} onClick={showKeyboardFn}></img>}

            {showKeyboard &&
                <div style={style} className='keyboard-top'>
                    
                        {
                            showFirst &&
                                <table style={divStyle}>
                                    {
                                        letters.map((letter, index) => {
                                            if (index == 0 || index == 6 || index == 12 || index == 18) {
                                                return (
                                                    <tr>
                                                        {
                                                            letters[index] ? <td key={index} style={tdStyle} onClick={(e) => showText(index, false)}>{letters[index]}</td> : ''
                                                        }
                                                        {
                                                            letters[index + 1] ? <td key={index + 1} style={tdStyle} onClick={(e) => showText(index + 1, false)}>{letters[index + 1]}</td> : ''
                                                        }
                                                        {
                                                            letters[index + 2] ? <td key={index + 2} style={tdStyle} onClick={(e) => showText(index + 2, false)}>{letters[index + 2]}</td> : ''
                                                        }
                                                        {
                                                            letters[index + 3] ? <td key={index + 3} style={tdStyle} onClick={(e) => showText(index + 3, false)}>{letters[index + 3]}</td> : ''
                                                        }
                                                        {
                                                            letters[index + 4] ? <td key={index + 4} style={tdStyle} onClick={(e) => showText(index + 4, false)}>{letters[index + 4]}</td> : ''
                                                        }
                                                        {
                                                            letters[index + 5] ? <td key={index + 5} style={tdStyle} onClick={(e) => showText(index + 5, false)}>{letters[index + 5]}</td> : ''
                                                        }
                                                    </tr>)
                                            }
                                        })
                                    }
                                </table> 
                        }

                        {
                            showSecond && 
                                <table>
                                    {
                                        letters2.map((letter, index) => {
                                            if (index == 0 || index == 5 || index == 10) {
                                                return (
                                                    <tr>
                                                        {
                                                            letters2[index] ? <td key={index} style={tdStyle} onClick={(e) => addText(letters2[index], false)}>{letters2[index]}</td> : ''
                                                        }
                                                        {
                                                            letters2[index + 1] ? <td key={index + 1} style={tdStyle} onClick={(e) => addText(letters2[index + 1], false)}>{letters2[index + 1]}</td> : ''
                                                        }
                                                        {
                                                            letters2[index + 2] ? <td key={index + 2} style={tdStyle} onClick={(e) => addText(letters2[index + 2], false)}>{letters2[index + 2]}</td> : ''
                                                        }
                                                        {
                                                            letters2[index + 3] ? <td key={index + 3} style={tdStyle} onClick={(e) => addText(letters2[index + 3], false)}>{letters2[index + 3]}</td> : ''
                                                        }
                                                        {
                                                            letters2[index + 4] ? <td key={index + 4} style={tdStyle} onClick={(e) => addText(letters2[index + 4], false)}>{letters2[index + 4]}</td> : ''
                                                        }
                                                        {
                                                            index == 10 ? <td key={index + 5} style={tdStyle} onClick={(e) => addText('B', false)}>B</td> : ''
                                                        }
                                                    </tr>)
                                            }
                                        })
                                    }
                                </table>
                        }

                    

                </div>
            }
        </>
    )
}

export default memo(KeyboardIcon)

