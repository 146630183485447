import React, { useState, memo,useEffect } from 'react';
import axios from "axios";
import './CreateFunction-component.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { alertStore, showAlert } from '../common/store';
import { getUserId,getSessionId } from '../common/Service';
import { apiUrl } from '../common/Config'
import KeyboardIcon from '../common/keyboard-icon'

//images
import imgCreateFunction from '../images/createFunction.png'

function CreateFunction(props) {

  const [functionName, setFunctionName] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [place, setPlace] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const sid = getSessionId()
    if (sid == '') {
    navigate('/login', { replace: true })
    }
}, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = pageValidation();
    if (error.length == 0) {

      let requestData = {
        functionName: functionName,
        description: description,
        date: date,
        place: place,
        userId: getUserId(),
        createdDate: new Date(),
        updatedDate: new Date()
      }

      axios.post(apiUrl + "function/createFunction", requestData)
      .then(response => {
        if(response.data.rowCount == 1) {
          clearData();
          alertStore.dispatch(showAlert({ message: t('functionSuccess'), status: 1 }))
        }
      })
      .catch(error => {
        alertStore.dispatch(showAlert({ message: t('someErrorOccured'), status: 0 }))
      });

    } else {
      alertStore.dispatch(showAlert({ message: error, status: 0 }))
    }
  }

  const pageValidation = () => {
    //field length given based on db input
    let error = [];
    if (functionName == "" || functionName == null) {
      error.push(t('valFunctionName'));
    } else if (functionName.length > 300) {
      error.push(t('valFunctionLength'));
    }

    if (place == "" || place == null) {
      error.push(t('valFunctionPlace'));
    } else if (place.length > 300) {
      error.push(t('valFunctionPlaceLength'));
    }

    return error;
  }

  const clearData = () => {
    setFunctionName('');
    setDescription('');
    setDate(new Date().toISOString().slice(0, 10));
    setPlace('');
  }

  const goToPage = () => {
    navigate('/dashboard', { replace: true });
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} className='create-function'>

      <div className='page-title'>
        <img src={imgCreateFunction}></img>
        <h3>{t('createFunction')}</h3>
      </div>

      <div className='div-position-relative'>
        <label>{t('functionName')} <span className='mandatory-field'>*</span></label>
        <input name="functionName" type="text" maxLength="300" value={functionName} onChange={(e) => setFunctionName(e.target.value)} />
        <KeyboardIcon input={functionName} sendToParent={(e) => setFunctionName(e)}></KeyboardIcon>
      </div>

      <div className='div-position-relative'>
        <label>{t('functionPlace')} <span className='mandatory-field'>*</span></label>
        <input name="place" type="text" value={place} maxLength="300" onChange={(e) => setPlace(e.target.value)} />
        <KeyboardIcon input={place} sendToParent={(e) => setPlace(e)}></KeyboardIcon>
      </div>

      <div className='div-position-relative test'>
        <label>{t('description')}</label>
        <textarea name="description" rows="4" cols="75" maxLength="600" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
        <KeyboardIcon input={description} sendToParent={(e) => setDescription(e)}></KeyboardIcon>
      </div>

      <div>
        <label>{t('date')}</label>
        <input name="date" type="date" value={date} onChange={(e) => setDate(e.target.value)} />
      </div>

      <div className='form-buttons'>
        <input type="button" className='back-button' value={t('goback')} onClick={goToPage} />
        <input type="submit" value={t('createFunction')} />
      </div>

    </form>
  );
}

export default memo(CreateFunction);