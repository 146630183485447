import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home-component';
import Login from './pages/Login-component';
import LogoutComponent from './pages/Logout-component';
import Dashboard from './pages/Dashboard-component';
import Registration from './pages/Registration-component';
import CreateFunction from './pages/CreateFunction-component';
import FunctionList from './pages/FunctionList-component';
import AddMoney from './pages/AddMoney-component';
import EditMoney from './pages/EditMoney-component';
import MoneyListComponent from './pages/MoneyList-Component';
import SearchMoneyComponent from './pages/SearchMoney-Component';
import ShowMessage from './common/Show-message-component';
import MenuComponent from './pages/Menu-component';
import AddSubUserComponent from './pages/AddSubUser-component'
import ProgressBar from './common/ProgressBar';
import './common/interceptors';
import { progressBarStore, alertStore } from './common/store';
import SearchComponent  from './pages/Search-component'

const App = () => {
  const [showProgressBar, setShowProgressBar] = useState(false);

  progressBarStore.subscribe(() => {
    let x = progressBarStore.getState()
    setShowProgressBar(x.value);
  })

  return (
    <div>
      <div className='app'>
        <ShowMessage></ShowMessage>
        {showProgressBar && <ProgressBar></ProgressBar>}

        <Router>

          <MenuComponent></MenuComponent>

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/*" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/createfunction" element={<CreateFunction />} />
            <Route path="/functionlist" element={<FunctionList />} />
            <Route path="/addmoney" element={<AddMoney />} />
            <Route path="/editmoney" element={<EditMoney />} />
            <Route path="/moneylist" element={<MoneyListComponent />} />
            <Route path="/searchmoney" element={<SearchMoneyComponent />} />
            <Route path="/menu" element={<MenuComponent />} />
            <Route path="/logout" element={<LogoutComponent />} />
            <Route path="/addsubuser" element={<AddSubUserComponent />} />
            <Route path="/search" element={<SearchComponent />} />
            <Route path="/search/:phonenumber" Component={SearchComponent} />
          </Routes>
        </Router>
      </div>
      <div className='copy-right'>moinottu.com © 2025 All rights reserved</div>
    </div>
  );
}

export default App;
